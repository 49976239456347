

const DangPhatTrienPage = () => {

    return (
        <div className="page-new">
            <p className="text-center">Chức năng đang phát triển</p>
        </div >

    );
};

export default DangPhatTrienPage;
