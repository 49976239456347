
const Footer = () => {

  return (
    <footer className="p-3 text-center">
      {/* <h6 className="mb-3">VNPT Long An</h6> */}
      {/* <p>VNPT Long An © Phát  triển 2024</p> */}
    </footer>
  );
};

export default Footer;
